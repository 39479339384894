import '../styles/globals.css'
import { Provider } from 'next-auth/client'
// import * as Sentry from "@sentry/react"
// import { Integrations } from "@sentry/tracing"
// import * as Sentry from '@sentry/node';

// if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
//   Sentry.init({
//     enabled: process.env.NODE_ENV === 'production',
//     dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
//     autoSessionTracking: true,
//     integrations: [
//       new Integrations.BrowserTracing(),
//     ],
  
//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 0.25,
//   });
//   console.log('Sentry is running',process.env.NEXT_PUBLIC_SENTRY_DSN)
// }


function MyApp({ Component, pageProps }) {
  return (
  <Provider session={pageProps.session}>
    <Component {...pageProps} />
  </Provider>
  )
}

export default MyApp
